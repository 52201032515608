import React, { useEffect, useState } from 'react';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
//import Paper from '@mui/material/Paper';
//import IconButton from '@mui/material/IconButton';
//import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { actionGetAOSBalance, actionGetCommissionRate } from "../store/action/remoteAction";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";
import { client } from "../store/action/remoteAction";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;
let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
//const App_Environment = process.env.REACT_APP_ENVIRONMENT;
const App_Environment = "0"; //To use Paymix Gateway for Demo

const OTCSellSettlement = (props) => {
    const [pageId, setPageId] = useState(0);
    const [aosBalance, setAosBalance] = useState(0);
    const [aosRemaining, setAosRemaining] = useState(0);
    const [aos, setAos] = useState(0);
    const [euro, setEuro] = useState(0);
    const [aosRate, setAosRate] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [transactionId, setTransactionId] = useState("");
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [language, setLanguage] = useState("");
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        //console.log(query);
        var vars = query.split("&");
        //console.log(vars); 
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            //console.log(pair);
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return ("");
    };

    const handleTokenChange = (e) => {
        resetTimout();
        let requestedAos = e.target.value.replace(/[^0-9.]/g, "");

        let nAmount = (parseFloat(requestedAos) * aosRate).toFixed(2);
        setAos(requestedAos);
        if (requestedAos) {
            setEuro(nAmount);
        }
        calculateCommissionAmount(requestedAos, nAmount);
    }

    const calculateCommissionAmount = (sellQuantity, sellAmount) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(sellQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(sellAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (parseFloat(sellAmount) > 0) {
            nTotalAmount = (parseFloat(sellAmount) - parseFloat(nCommissionAmount)).toFixed(2);
        }
        setTotalAmount(nTotalAmount);
    };

    /*const goToPaymentPage = (e) => {
        resetTimout();
        window.location.href = '/tokens-sell-settlement?tid=' + props.transactions[e].TransactionID;
    };*/

    const goToConfirmationPage = (e) => {
        resetTimout();
        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || euro <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < parseFloat(aos)) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        setPageId(2);
    };

    const goToPrevPage = (e) => {
        resetTimout();
        setPageId(1);
    };


    const goToCheckout = async (e) => {
        resetTimout();
        e.preventDefault();

        if (!transactionId) {
            alert('Invalid Selection');
            return;
        }
        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0 || euro <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aos) > aosRemaining) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < parseFloat(aos)) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let userId = localStorage.getItem("uid");
            setIsButtonClicked(true);
            const request = {
                "UserId": userId,
                "CryptoAsset": "AOS",
                "BuySellFlag": 8,
                "Currency": "EURO",
                "FromWalletId": userId,
                "ToWalletID": "",
                "Quantity": "" + aos + "",
                "UnitPrice": aosRate,
                "Amount": euro,
                "PaymentType": 0,
                "CommissionID": commissionID,
                "CommissionAmount": commissionAmount,
                "OtcFlag": 1,
                "tid": transactionId,
                "PartialOrderFlag": 0
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions`, request).then((response) => {
                setAos(0);
                setEuro(0);
                window.location.href = '/transactions';
            }).catch(error => {
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error! Please contact customer support");
                }
                console.log(error);
                setIsButtonClicked(false);
            });
        }

    };

    const getOrderDetails = async (tid) => {
        if (!tid) {
            return;
        }
        client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otc/${tid}`).then((response) => {
            if (response.status == 200 && response.data.length === 1) {
                let txnSell = response.data[0];
                if (parseFloat(txnSell.RemainingQuantity) > 0) {
                    setAos(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setAosRemaining(parseFloat(txnSell.RemainingQuantity).toFixed(2));
                    setAosRate(parseFloat(txnSell.UnitPrice).toFixed(2));
                    let nAmount = (parseFloat(txnSell.UnitPrice) * parseFloat(txnSell.RemainingQuantity)).toFixed(2);
                    setEuro(nAmount);
                    setTransactionId(txnSell.TransactionID);
                    calculateCommissionAmount(parseFloat(txnSell.RemainingQuantity).toFixed(2), nAmount);
                    if (txnSell.PartialOrderFlag === 1) {
                        setIsPartialOrderFlag(true);
                    } else {
                        setIsPartialOrderFlag(false);
                    }
                    setPageId(1);
                } else {
                    handleOrderError();
                }
            } else {
                handleOrderError();
            }
        }).catch(error => {
            handleOrderError();
            console.log(error);
        });
    };

    const handleOrderError = () => {
        let sLanguage = localStorage.getItem("locale");
        alert(getMultiLanguageMessage('msg_InvalidSellOrder', sLanguage));
        //window.location.href = '/tokens-sell-settlement';
    };

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        //actionGetUserProfile request send from Navbar
        let tid = getQueryVariable("tid");
        if (tid && tid.length > 0) {
            setTransactionId(tid);
            props.actionGetAOSBalance();
        } else {
            alert('Invalid Selection');
        }
    }, []);

    useEffect(() => {
        if (props?.users && props.users.length > 0) {
        }
    }, [props.users]);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.userBalances?.length > 0) {
            props.userBalances[0]?.Balance ? setAosBalance((props.userBalances[0]?.Balance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.userBalances]);

    useEffect(() => {
        if (transactionId) {
            props.actionGetCommissionRate(8);
        }
    }, [transactionId]);    

    useEffect(() => {
        if (transactionId) {
            getOrderDetails(transactionId);
        }
    }, [props.commissionRates]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-OTCSell-Settlement')}</h2>
            {pageId === 1 &&
                <h2 className="input-item-label">{translate('AOS-Token-Balance')} = {aosBalance}</h2>
            }
            <div className="gaps-1x" />

            {/* Order Selcted from OtcOrderList
            {pageId === 0 &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className="data-table tranx-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" component="th" className="tranx-no">{translate('DateTime')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-token">{translate('Tokens')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-amount">{translate('UnitPrice')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-amount">{translate('Amount')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-to">{translate('Partial-Order')}</TableCell>
                                <TableCell align="center" component="th" className="tranx-action" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props?.transactions?.map((txn, index) => {
                                return <TableRow key={txn.TransactionID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align='center'>
                                        <span>{txn.DateCreated}</span>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.RemainingQuantity}</span>{txn.CryptoAsset}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.UnitPrice}</span>{translate('Euro')}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{(parseFloat(txn.RemainingQuantity) * parseFloat(txn.UnitPrice)).toFixed(2)}</span>{translate('Euro')}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <span>{txn.PartialOrderFlag === 1 ? 'OK' : 'NG'}</span>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => goToPaymentPage(index)}>
                                            <AddShoppingCartIcon data-index={index} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            */}

            {pageId === 1 &&
                <div>
                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Order-Quantity')}
                            id="outlined-start-adornment-aos"
                            sx={{ width: '24.5ch' }}
                            value={aos}
                            onChange={handleTokenChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                            }}
                            disabled={isPartialOrderFlag ? false : true}
                        />
                    </div>

                    <div className="gaps-2x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Rate')}
                            id="outlined-start-adornment-rate"
                            sx={{ width: '24.5ch' }}
                            value={aosRate}
                            defaultValue={aosRate}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                            }}
                        />
                    </div>

                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Commission-Amount')}
                            id="commission-amount"
                            sx={{ width: '24.5ch' }}
                            value={commissionAmount}
                            defaultValue={commissionAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="gaps-1x" />
                    <div>
                        <TextField
                            size="small"
                            label={translate('Payment-Amount')}
                            id="outlined-start-adornment-euro"
                            sx={{ width: '24.5ch' }}
                            value={totalAmount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{translate('Euro')}</InputAdornment>,
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="gaps-2x" />
                    <a
                        href="#"
                        className="btn btn-primary payment-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#tranxETH"
                        onClick={event => goToConfirmationPage(event)}
                    >
                        {translate('To-Confirm-Page')}
                    </a>
                </div>
            }

            {pageId === 2 &&
                <div>
                    <table width='70%'>
                        <tbody>
                            <tr>
                                <td align='left' >{translate('Order-Quantity')} :</td>
                                <td align='right' >{aos} AOS</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosRate} EUR/AOS </td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Amount')} :</td>
                                <td align='right'>{euro} EUR</td>
                            </tr>
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{commissionAmount} EUR</td>
                            </tr>
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{totalAmount} EUR</td>
                            </tr>
                        </tbody>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToPrevPage(event)}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={event => goToCheckout(event)}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
        </div>

    );
};

const mapStateToProps = state => {
    return {
        transactions: state.remote.otctransactions,
        userBalances: state.remote.userBalances,
        users: state.remote.users,
        promoter: state.remote.promoter,
        commissionRates: state.remote.commissionRates,
    };
};

export default connect(mapStateToProps, {
    actionGetAOSBalance,
    actionGetCommissionRate
})(OTCSellSettlement);
