import React from 'react';
import { LOCALES } from '../i18n/constants';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const BidsTableBody = ({ tableData, columns, language, kycStatus }) => {

    const goToPaymentPage = (buySellFlag, transactionId) => {
        if (buySellFlag === 2) {
            window.location.href = '/bids?tid=' + transactionId;
        } else if (buySellFlag === 7) {
            window.location.href = 'tokens-sell-settlement?tid=' + transactionId;
        }
    };

    return (
        <tbody>
            {tableData.map((data) => {
                return (
                    <tr key={data.AUTORECID}>
                        {columns.map(({ accessor }) => {
                            let dispData = data[accessor] ? data[accessor] : "——";
                            if (accessor === 'RemainingQuantity') {
                                dispData += 'AOS';
                            } else if (accessor === 'BuySellFlag') {
                                dispData = dispData === 2 ? (language === LOCALES.JAPANESE ? '売' : 'Sell') : (language === LOCALES.JAPANESE ? '買' : 'Buy');
                            } else if (accessor === 'UnitPrice') {
                                dispData += language === LOCALES.JAPANESE ? 'EUR' : 'EUR';
                            } else if (accessor === 'Amount') {
                                dispData = (parseFloat(data['RemainingQuantity']) * parseFloat(data['UnitPrice'])).toFixed(2);
                                dispData += language === LOCALES.JAPANESE ? 'EUR' : 'EUR';
                            } else if (accessor === 'DateCreated') {
                                dispData = dispData.substring(0, 10).replaceAll("-", "/");
                            } else if (accessor === 'PartialOrderFlag') {
                                dispData = dispData === 1 ? 'OK' : 'NG';
                            } else if (accessor === 'BuyButton') {
                                dispData = data.TransactionID;
                            }

                            if (accessor === 'BuyButton') {
                                return kycStatus ? <td key={accessor}><IconButton color="primary" size="small" aria-label="add to shopping cart" onClick={() => goToPaymentPage(data['BuySellFlag'], dispData)}><AddShoppingCartIcon data-index={data.id} /></IconButton></td> : <td key={accessor}></td>;
                            } else if (accessor === 'BuySellFlag') {
                                return <td vertical-align='center' key={accessor}><div className={data['BuySellFlag'] === 2 ? 'Message-Red' : 'Message-Blue'}>{dispData}</div></td>;
                            } else {
                                return <td vertical-align='center' key={accessor}>{dispData}</td>
                            }
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

export default BidsTableBody;