import * as actionTypes from '../action';
import axios from "axios";
import { logout } from "../../components/common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;
let BASE_URL_SERVER = process.env.REACT_APP_SERVER_URL;

export const client = (token = null) => {
    const defaultOptions = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };

    return {
        get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
        post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
        put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
        delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
    };
};

export const actionGetUser = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_DATA_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/userdetails/${uid}/detail`);
        dispatch({ type: actionTypes.USER_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.USER_DATA_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        logout();
    }
};

export const actionGetUserProfile = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_DATA_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/userdetails/${uid}/full`);
        dispatch({ type: actionTypes.USER_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.USER_DATA_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        logout();
    }
};

export const actionGetCurrencyAos = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CURRENCY_DATA_LOADING_AOS, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let result = await client(token).get(`${BASE_URL}/currency/AOS`);
        
        dispatch({ type: actionTypes.CURRENCY_DATA_LOADING_AOS, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.CURRENCY_DATA_SUCCESS_AOS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetCurrencyEuro = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CURRENCY_DATA_LOADING_EURO, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let result = await client(token).get(`${BASE_URL}/currency/EURO`);

        dispatch({ type: actionTypes.CURRENCY_DATA_LOADING_EURO, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.CURRENCY_DATA_SUCCESS_EURO, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetTranche = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANCHE_DATA_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let result = await client(token).get(`${BASE_URL_SERVER}/progress`);

        dispatch({ type: actionTypes.TRANCHE_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANCHE_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionCreateUserProfile = (data) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_CREATE_LOADING, payload: true });
    try {
        let result = await client(localStorage.getItem("@token")).post(`${BASE_URL}/users`, data);
        dispatch({ type: actionTypes.USER_CREATE_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_CREATE_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetUserTransaction = (filters) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_DATA_LOADING, payload: true });
    try {
        let uid = localStorage.getItem("uid");
        let result = await client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/user?sort=["T.AUTORECID","desc"]&filter={"UserId":"${uid}"${filters}}`);

        dispatch({ type: actionTypes.TRANSACTION_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetUserExecution = (filters) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.EXECUTION_DATA_LOADING, payload: true });
    try {
        let uid = localStorage.getItem("uid");
        let result = await client(localStorage.getItem("@token")).get(`${BASE_URL}/executions/user?sort=["AUTORECID","desc"]&filter={"UserId":"${uid}"${filters}}`);

        dispatch({ type: actionTypes.EXECUTION_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.EXECUTION_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetOtcTransaction = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: true });
    try {
        let result = await client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otc`);

        dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetOtcSellTransaction = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: true });
    try {
        let result = await client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otcsell`);

        dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetOtcBuyTransaction = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: true });
    try {
        let result = await client(localStorage.getItem("@token")).get(`${BASE_URL}/transactions/otcbuy`);

        dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_OTC_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetCashBalance = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_BALANCE_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/cashbalance/${uid}`);
        dispatch({ type: actionTypes.USER_BALANCE_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_BALANCE_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.USER_BALANCE_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        dispatch({ type: actionTypes.USER_BALANCE_ERROR, payload: error });
    }
};

export const actionGetAOSBalance = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_BALANCE_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/aosbalance/${uid}`);
        dispatch({ type: actionTypes.USER_BALANCE_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_BALANCE_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.USER_BALANCE_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        dispatch({ type: actionTypes.USER_BALANCE_ERROR, payload: error });
    }
};

export const actionGetUserCashTransaction = (filters) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_DATA_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }
        let result = await client(token).get(`${BASE_URL}/cashtransaction?sort=["DateCreated","desc"]&filter={"UserID":"${uid}"${filters}}`);
        dispatch({ type: actionTypes.TRANSACTION_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetBankAccount = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.USER_BANKACCOUNT_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/bankaccount/user/${uid}`);
        dispatch({ type: actionTypes.USER_BANKACCOUNT_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.USER_BANKACCOUNT_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.USER_BANKACCOUNT_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        dispatch({ type: actionTypes.USER_BANKACCOUNT_ERROR, payload: error });
    }
};

export const actionGetLastTransactionPrice = (filters) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.TRANSACTION_PRICE_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }
        let result = await client(token).get(`${BASE_URL}/transactions/lastprice`);
        dispatch({ type: actionTypes.TRANSACTION_PRICE_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.TRANSACTION_PRICE_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
        }
    } catch (error) {
        console.log(error, 5);
    }
};

export const actionGetPromoterApplication = () => async (dispatch, getState) => {
    dispatch({ type: actionTypes.PROMOTER_APPLICATION_DATA_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }

        let result = await client(token).get(`${BASE_URL}/promoterrequest/user/${uid}`);
        dispatch({ type: actionTypes.PROMOTER_APPLICATION_DATA_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.PROMOTER_APPLICATION_DATA_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.PROMOTER_APPLICATION_DATA_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        dispatch({ type: actionTypes.PROMOTER_APPLICATION_DATA_ERROR, payload: error });
    }
};

export const actionGetCommissionRate = (buySellFlag) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.COMMISSION_RATE_LOADING, payload: true });
    try {
        let token = localStorage.getItem("@token");
        let uid = localStorage.getItem("uid");
        if (uid === null || token === null) {
            logout();
            return;
        }
        let result = await client(token).get(`${BASE_URL}/commissionrate/order/${buySellFlag}`);
        dispatch({ type: actionTypes.COMMISSION_RATE_LOADING, payload: false });
        if (parseInt(result.status) === 200) {
            dispatch({ type: actionTypes.COMMISSION_RATE_SUCCESS, payload: result.data });
        } else if (parseInt(result.status) === 403) {
            logout();
        } else {
            console.log(result, 8);
            dispatch({ type: actionTypes.COMMISSION_RATE_ERROR, payload: result });
        }
    } catch (error) {
        console.log(error, 5);
        dispatch({ type: actionTypes.COMMISSION_RATE_ERROR, payload: error });
    }
};

export const formatDate = (dtInput) => {
    try {
        var d = new Date(dtInput);
        if (isNaN(d)) {
            return null;
        }
        var sMonth = '' + (d.getMonth() + 1);
        var sDay = '' + d.getDate();
        var nYear = d.getFullYear();

        if (sMonth.length < 2) {
            sMonth = '0' + sMonth;
        }
        if (sDay.length < 2) {
            sDay = '0' + sDay;
        }

        return [nYear, sMonth, sDay].join('-');
    } catch (error) {
        console.log(error);
        return null;
    }
};
