import { LOCALES } from './constants';

export const getMultiLanguageLabel = (id, language) => {
    let displayText = '';
    if (!language) {
        language = LOCALES.ENGLISH;
    }
    if (id === "lbl_Deposit") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Deposit';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '入金';
        }
    } else if (id === "lbl_Withdraw") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Withdraw';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '出金';
        }
    } else if (id === "lbl_Created") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Created';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '受付';
        }
    } else if (id === "lbl_Processed") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Processed';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '完了';
        }
    } else if (id === "lbl_Cancelled") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Cancelled';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '取消';
        }
    } else if (id === "lbl_ConfirmDialog_Title") {
        if (language === LOCALES.ENGLISH) {
            displayText = '';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '';
        }
    } else if (id === "lbl_Cancel") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Cancel';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'キャンセル';
        }
    } else if (id === "lbl_MakeDeposit") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Deposit';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '入金する';
        }
    } else if (id === "lbl_Close") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Close';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '閉じる';
        }
    } else if (id === "lbl_GoToWebPage") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Go To web page';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'Webページへ';
        }
    } else if (id === "lbl_Change") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Change';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '変更する';
        }
    } else if (id === "lbl_DoNotChange") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Not Change';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '変更しない';
        }
    } else if (id === "lbl_") {
        if (language === LOCALES.ENGLISH) {
            displayText = '';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '';
        }
    }

    if (!displayText) {
        displayText = id;
    }

    return displayText;
};

export const getMultiLanguageMessage = (id, language) => {
    let displayText = '';

    if (!language) {
        language = LOCALES.ENGLISH;
    }
    if (id === "msg_WithdrawCancel_Confirm") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Are you sure you want to Cancel the Withdraw?';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '出金依頼をキャンセルしてもよろしいですか?';
        }
    } else if (id === "msg_WithdrawCancel_Success") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Withdraw request successfully cancelled';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '出金依頼は正常にキャンセルされました';
        }
    } else if (id === "msg_WithdrawCancel_Failed") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Failed to cancel Withdraw request';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '出金依頼のキャンセルに失敗しました';
        }
    } else if (id === "msg_OrderCancel_Confirm") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Are you sure you want to Cancel the Order?';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '注文をキャンセルしてもよろしいですか?';
        }
    } else if (id === "msg_OrderCancel_Success") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Order successfully canceled';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '注文は正常にキャンセルされました';
        }
    } else if (id === "msg_OrderCancel_Failed") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Failed to cancel the Order';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '注文のキャンセルに失敗しました';
        }
    } else if (id === "msg_Confirm_Proceed") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Are you sure you want to Proceed?';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '続行してもよろしいですか?';
        }
    } else if (id === "msg_IncorrectCoin_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Incorrect coins';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'トークンは無効です。';
        }
    } else if (id === "msg_InvalidAmount_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Invalid Amount';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '入力された金額が無効です。';
        }
    } else if (id === "msg_InsufficientBalance_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Insufficient Balance';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '残高不足しているため続行できません。';
        }
    } else if (id === "msg_InsufficientCoin_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Insufficient AOS Balance';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'トークン残高不足しているため続行できません。';
        }
    } else if (id === "msg_EmptyWalletAddress") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Enter wallet address for gifting.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'ギフト用のウォレットアドレスを入力してください。';
        }
    } else if (id === "msg_EmptyPassportNumber") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter passport number';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'パスポート番号を入力してください。';
        }
    } else if (id === "msg_EmptyPassportExpiryDate") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter passport expiry date';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'パスポートの有効期限を入力してください。';
        }
    } else if (id === "msg_EmptyPassportFront") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload passport front page';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'パスポートのフロントページをアップロードしてください。';
        }
    } else if (id === "msg_EmptyNationalIDNumber") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter nationalId number';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'マイナンバーカード番号を入力してください。';
        }
    } else if (id === "msg_EmptyNationalIDExpiryDate") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter national expiry date';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'マイナンバーカードの有効期限を入力してください。';
        }
    } else if (id === "msg_EmptyNationalIDFront") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload national Id front page';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'マイナンバーカードのフロントページをアップロードしてください。';
        }
    } else if (id === "msg_EmptyLicenseNumber") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter license number';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '運転免許証番号を入力してください。';
        }
    } else if (id === "msg_EmptyLicenseExpiryDate") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter license expiry date';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '運転免許証の有効期限を入力してください。';
        }
    } else if (id === "msg_EmptyLicenseFront") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload license front page';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '運転免許証のフロントページをアップロードしてください。';
        }
    } else if (id === "msg_emptybankname_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter bank name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '銀行名を入力してください。';
        }
    } else if (id === "msg_emptybranchname_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter branch name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '支店名を入力してください。';
        }
    } else if (id === "msg_emptyiban_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter iban';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'IBANを入力してください。';
        }
    } else if (id === "msg_emptybankaddress_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter bank address';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '銀行住所を入力してください。';
        }
    } else if (id === "msg_emptyaccountno_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter accunt number';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '口座番号を入力してください。';
        }
    } else if (id === "msg_emptyaccountname_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter account name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '口座名を入力してください。';
        }
    } else if (id === "msg_emptyswiftcode_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter swift code';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'スウィフトコードを入力してください。';
        }
    } else if (id === "msg_bankaccount_Success") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Bank Account successfully registered';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '銀行口座が正常に登録されました。';
        }
    } else if (id === "msg_bankaccount_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Failed to register Bank Account';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '銀行口座の登録に失敗しました。';
        }
    } else if (id === "msg_InsufficientAOSBalance_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Insufficient AOS Balance. Available Balance: ';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'トークン残高不足しているため続行できません。トークン残高：';
        }
    } else if (id === "msg_EmptyUserNameLast") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter last name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '姓（ローマ字）を入力してください。';
        }
    } else if (id === "msg_EmptyUserNameFirst") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter first name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '名（ローマ字）を入力してください。';
        }
    } else if (id === "msg_EmptyPhoneNumber") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter phone number';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '電話番号を入力してください。';
        }
    } else if (id === "msg_EmptyUserNameLast_DByte") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter kanji last name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '姓（漢字）を入力してください。';
        }
    } else if (id === "msg_EmptyUserNameFirst_DByte") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter kanji first name';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '名（漢字）を入力してください。';
        }
    } else if (id === "msg_EmptyCountry") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please select country';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '国を選択してください。';
        }
    } else if (id === "msg_EmptyPostalCode") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter zip code';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '郵便番号を入力してください。';
        }
    } else if (id === "msg_EmptyAddress1") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter Address(Prefecture, City, Ward)';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '住所　ローマ字　（都道府県、市区町村）を入力してください。';
        }
    } else if (id === "msg_EmptyAddress2") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter Address(Street, building, room)';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '住所　ローマ字　（町名番地、建物名、号室）を入力してください。';
        }
    } else if (id === "msg_EmptyAddress1_DByte") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter address in Kanji';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '漢字住所（都道府県、市区町村）を入力してください。';
        }
    } else if (id === "msg_EmptyAddress2_DByte") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please enter address in Kanji';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '漢字住所（町名番地、建物名、号室）を入力してください。';
        }
    } else if (id === "msg_EmptyDOB") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please entry Date Of Birth';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '生年月日を入力してください。';
        }
    } else if (id === "msg_EmptyNationality") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please select nationality';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '国籍を選択してください。';
        }
    } else if (id === "msg_EmptyIDProof") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload personal document';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '本人確認資料をアップロードしてください。';
        }
    } else if (id === "msg_EmptyApplicantPhoto") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload applicant photo';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '正面からの写真をアップロードしてください。';
        }
    } else if (id === "msg_EmptyProfilePhoto") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please upload profile photo';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '斜め前からの写真をアップロードしてください。';
        }
    } else if (id === "msg_InvalidSellOrder") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Selected sell order is not valid.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '選択した売り注文は無効です。';
        }
    } else if (id === "msg_Confirm_Replace_PPP") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Public Purchase Price €xxx available for purchase. Do you want to change the unit price to the Public Purchase Price?';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '公開買取価格 xxxユーロでの買取が可能です。単価を公開買取価格に変更しますか？';
        }
    } else if (id === "msg_WalletNotFound_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Wallet not found.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'ウォレットが見つかりません。';
        }
    } else if (id === "msg_KYCNotCompleted") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'You are not allowed to use services because KYC procedure has not been completed. Please complete the procedure on the web page and use the services after verifying KYC.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'ご本人様確認の手続きが未完了のため、ご利用いただけません。お手数ですが、WEBページよりお手続きし、ご本人様確認完了後にご利用をお願いいたします。';
        }
    } else if (id === "msg_ChangeRequest_Agree") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please check Agree to the above condition.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '上記の条件に同意するにチェックを入れてください。';
        }
    } else if (id === "msg_Agree_NotSelected_Error") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Please check Agree.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '同意するにチェックを入れてください。';
        }
    } else if (id === "msg_GoToDepositPage_Confirm") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'To add a withdrawal account, deposit must be made from that account';
        } else if (language === LOCALES.JAPANESE) {
            displayText = '出金先の口座を追加するには、その口座からの入金が必要です';
        }
    } else if (id === "msg_UserSuspended_NoAccess") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Your transactions are restricted';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'お客様のお取引を制限させていただいております。';
        }
    } else if (id === "msg_KycInProgress_NoAccess") {
        if (language === LOCALES.ENGLISH) {
            displayText = 'Your KYC is currently being verified. Please wait for KYC verification to be completed.';
        } else if (language === LOCALES.JAPANESE) {
            displayText = 'ただいまKYCを受付中です。KYC認証完了までしばらくお待ちください。';
        }
    }

    return displayText;
};