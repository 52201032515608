import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCurrencyEuro, actionGetCashBalance, actionGetCommissionRate } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

const OtcBuyToken = (props) => {
    const [aosBalance, setAosBalance] = useState(0);
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [aos, setAos] = useState(0);
    const [euro, setEuro] = useState(0);
    const [aosBuyPrice, setAosBuyPrice] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [paymentType, setPaymentType] = useState("");
    const [euroBalance, setEuroBalance] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const onChangePaymentType = (e) => {
        resetTimout();
        setPaymentType(e.target.value);
    };

    const goToConfirmationPage = async () => {
        resetTimout();

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (parseFloat(aosBuyPrice) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (parseFloat(euro) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (paymentType == 'Cash' && parseFloat(euroBalance) < parseFloat(euro)) {
            alert(getMultiLanguageMessage('msg_InsufficientBalance_Error', language));
            return;
        }
        setPageId(1);
    };

    const goToPrevPage = () => {
        resetTimout();
        setPageId(0);
    };

    const submitOtcBuyTransaction = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            let serverUrl = '';
            let bodyJSON = JSON.stringify({
                UserId: uid,
                CryptoAsset: "AOS",
                BuySellFlag: 7,
                Currency: "EURO",
                FromWalletId: uid,
                ToWalletID: "",
                Quantity: "" + aos + "",
                UnitPrice: aosBuyPrice,
                Amount: euro,
                CommissionID: commissionID,
                CommissionAmount: commissionAmount,
                OtcFlag: 1,
                PartialOrderFlag: isPartialOrderFlag ? 1 : 0
            });

            if (paymentType === 'CreditCard') {
                serverUrl = SERVER_URL + `/create-checkout-session-paymix-open-otcbuy`;
            } else if (paymentType === 'Cash') {
                serverUrl = SERVER_URL + `/otcbuy-Open-cashbalance`;
            } else {
                return;
            }

            if (serverUrl) {
                setIsButtonClicked(true);
                fetch(serverUrl, {
                    method: 'POST',
                    redirect: 'follow',
                    headers: { 'Content-Type': 'application/json' },
                    body: bodyJSON,
                })
                    .then(res => {
                        if (res.ok) return res.json()
                        return res.json().then(json => Promise.reject(json))
                    })
                    .then((body) => {
                        window.location.href = body.url;
                    })
                    .catch((error) => {
                        let errorMessage = "Error: ";
                        if (error && error.message) {
                            errorMessage += error.message;
                        } else {
                            errorMessage += JSON.stringify(error)
                        }
                        alert(errorMessage);
                        setIsButtonClicked(false);
                    });
            }
        }

    };

    const partialOrderOnChange = (e) => {
        resetTimout();
        setIsPartialOrderFlag(!isPartialOrderFlag);
    };

    const handleChange = (e) => {
        resetTimout();
        let nAmount = 0;
        switch (e.target.id) {
            case "outlined-start-adornment-aos":
                let requestedAos = e.target.value.replace(/[^0-9.]/g, "");
                setAos(requestedAos);
                if (parseFloat(requestedAos) > 0 && parseFloat(aosBuyPrice) > 0) {
                    nAmount = (parseFloat(requestedAos) * parseFloat(aosBuyPrice)).toFixed(2);
                }
                setEuro(nAmount);
                calculateCommissionAmount(requestedAos, nAmount);
                break;
            case "outlined-start-adornment-rate":
                let requestedPrice = e.target.value.replace(/[^0-9.]/g, "");
                setAosBuyPrice(requestedPrice);
                if (parseFloat(aos) > 0 && parseFloat(requestedPrice) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(requestedPrice)).toFixed(2);
                }
                calculateCommissionAmount(aos, nAmount);
                setEuro(nAmount);
                break;
            default: console.log('err');
        }
    };

    const calculateCommissionAmount = (buyQuantity, buyAmount) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            alert("Unexpected Error. Please contact customer support.");
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(buyQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(buyAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (parseFloat(buyAmount) > 0) {
            nTotalAmount = (parseFloat(buyAmount) + parseFloat(nCommissionAmount)).toFixed(2);
        }
        setTotalAmount(nTotalAmount);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCurrencyEuro();
        props.actionGetCashBalance();
        props.actionGetCommissionRate(7);
    }, []);

    useEffect(() => {
        if (props.userBalances?.length > 0) {
            let userBalance = props.userBalances[0];
            setEuroBalance(userBalance.Balance);
        }
    }, [props.userBalances]);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.userBalances?.length > 0) {
            props.userBalances[0]?.Balance ? setAosBalance((props.userBalances[0]?.Balance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.userBalances]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Menu-OTCBuy-Open')}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={aos}
                                        defaultValue={aos}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div>
                                    <div className="gaps-2x" />
                                    <TextField
                                        size="small"
                                        label={translate('Rate')}
                                        id="outlined-start-adornment-rate"
                                        sx={{ width: '24.5ch' }}
                                        value={aosBuyPrice}
                                        defaultValue={aosBuyPrice}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                        }}
                                    />
                                </div>

                                <div>
                                    <div className="gaps-1x" />
                                    <FormControlLabel control={<Checkbox
                                        onChange={partialOrderOnChange}
                                        checked={isPartialOrderFlag}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label={translate('Partial-Order')} />
                                </div>

                                <div>
                                    <div className="gaps-1x" />
                                    <TextField
                                        size="small"
                                        label={translate('Commission-Amount')}
                                        id="commission-amount"
                                        sx={{ width: '24.5ch' }}
                                        value={commissionAmount}
                                        defaultValue={commissionAmount}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                        }}
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <div className="gaps-1x" />
                                    <TextField
                                        size="small"
                                        label={translate('Payment-Amount')}
                                        id="payment-amount"
                                        sx={{ width: '24.5ch' }}
                                        value={totalAmount}
                                        defaultValue={totalAmount}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                        }}
                                        disabled={true}
                                    />
                                </div>

                                <div className="gaps-1x" />
                                <div onChange={onChangePaymentType}>
                                    <input className="payment-Type" type="radio" value="Cash" name="paymentType" checked={paymentType === 'Cash' ? true : false} /> {translate('Payment-By-Cash')}
                                    <input className="payment-Type" type="radio" value="CreditCard" name="paymentType" checked={paymentType === 'CreditCard' ? true : false} /> {translate('Payment-By-CreditCard')}
                                </div>
                                <div className="gaps-1x" />

                                <div className="gaps-1x" />
                                {paymentType === 'Cash' &&
                                    <div>
                                        <div className="col-md-8">
                                            <label className="input-item-label">{translate('Cash-Balance')}&nbsp;:&nbsp;{euroBalance} {translate('Euro')}</label>
                                        </div>
                                    </div>
                                }
                                <div className="gaps-1x" />
                                {(paymentType === 'Cash' || paymentType === 'CreditCard') &&
                                    <a
                                        href="#"
                                        className="btn btn-primary payment-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#tranxETH"
                                        onClick={event => goToConfirmationPage(event)}
                                    >
                                        {translate('To-Confirm-Page')}
                                    </a>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="gaps-1x" />
                </div>
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tr>
                            <td align='left' >{translate('Order-Quantity')} :</td>
                            <td align='right' >{aos} AOS</td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Rate')} :</td>
                            <td align='right'>{aosBuyPrice} EUR/AOS </td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Amount')} :</td>
                            <td align='right'>{euro} EUR</td>
                        </tr>
                        <tr>
                            <td align='left'>{translate('Commission-Amount')} :</td>
                            <td align='right'>{commissionAmount.toFixed(2)} EUR</td>
                        </tr>
                        <tr>
                            <td align='left' >{translate('Payment-Amount')} :</td>
                            <td align='right'>{(parseFloat(euro) + parseFloat(commissionAmount)).toFixed(2)} EUR</td>
                        </tr>
                        <tr>
                            <td align='left'>{translate('Payment-Type')} :</td>
                            <td align='right'>{paymentType === 'CreditCard' ? translate('Payment-By-CreditCard') : translate('Payment-By-WalletBalance')} </td>
                        </tr>
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={goToPrevPage}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitOtcBuyTransaction}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        euroRate: state.remote.currencyEuro,
        userBalances: state.remote.userBalances,
        commissionRates: state.remote.commissionRates,
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCurrencyEuro,
    actionGetCashBalance,
    actionGetCommissionRate
})(OtcBuyToken);
