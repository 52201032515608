import * as actionTypes from '../action';

const initialState = {
    userLoader: false,
    users: [],
    userCreateLoader: false,
    user: {},
    transactionLoader: false,
    transactions: [],
    transactionPrice: [],
    trancheLoader: false,
    currencyAosLoader: false,
    currencyAos: [],
    currencyEuroLoader: false,
    currencyEuro: [],
    userBalances: [],
    userBankAccounts: [],
    promoterLoader: false,
    promoter: [],
    executionLoader: false,
    executions: [],
};

const remoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_DATA_LOADING:
            return { ...state, userLoader: action.payload };
        case actionTypes.USER_DATA_SUCCESS:
            return { ...state, users: action.payload };
        case actionTypes.USER_DATA_ERROR:
            return { ...state, usersError: action.payload };
        case actionTypes.USER_CREATE_LOADING:
            return { ...state, userCreateLoader: action.payload };
        case actionTypes.USER_CREATE_SUCCESS:
            return { ...state, user: action.payload };
        case actionTypes.TRANSACTION_DATA_LOADING:
            return { ...state, transactionLoader: action.payload };
        case actionTypes.TRANSACTION_DATA_SUCCESS:
            return { ...state, transactions: action.payload };
        case actionTypes.TRANSACTION_OTC_DATA_LOADING:
            return { ...state, otctransactionLoader: action.payload };
        case actionTypes.TRANSACTION_OTC_DATA_SUCCESS:
            return { ...state, otctransactions: action.payload };
        case actionTypes.EXECUTION_DATA_LOADING:
            return { ...state, executionLoader: action.payload };
        case actionTypes.EXECUTION_DATA_SUCCESS:
            return { ...state, executions: action.payload };
        case actionTypes.CURRENCY_DATA_LOADING_AOS:
            return { ...state, currencyAosLoader: action.payload };
        case actionTypes.CURRENCY_DATA_SUCCESS_AOS:
            return { ...state, currencyAos: action.payload };
        case actionTypes.CURRENCY_DATA_LOADING_EURO:
            return { ...state, currencyEuroLoader: action.payload };
        case actionTypes.CURRENCY_DATA_SUCCESS_EURO:
            return { ...state, currencyEuro: action.payload };
        case actionTypes.TRANCHE_DATA_LOADING:
            return { ...state, trancheLoader: action.payload };
        case actionTypes.TRANCHE_DATA_SUCCESS:
            return { ...state, tranche: action.payload };
        case actionTypes.USER_BALANCE_SUCCESS:
            return { ...state, userBalances: action.payload };
        case actionTypes.USER_BANKACCOUNT_SUCCESS:
            return { ...state, userBankAccounts: action.payload };
        case actionTypes.TRANSACTION_PRICE_LOADING:
            return { ...state, transactionLoader: action.payload };
        case actionTypes.TRANSACTION_PRICE_SUCCESS:
            return { ...state, transactionPrice: action.payload };
        case actionTypes.PROMOTER_APPLICATION_DATA_LOADING:
            return { ...state, promoterLoader: action.payload };
        case actionTypes.PROMOTER_APPLICATION_DATA_SUCCESS:
            return { ...state, promoter: action.payload };
        case actionTypes.PROMOTER_APPLICATION_DATA_ERROR:
            return { ...state, promoterError: action.payload };
        case actionTypes.COMMISSION_RATE_LOADING:
            return { ...state, commissionRateLoader: action.payload };
        case actionTypes.COMMISSION_RATE_SUCCESS:
            return { ...state, commissionRates: action.payload };
        case actionTypes.COMMISSION_RATE_ERROR:
            return { ...state, commissionRateError: action.payload };
        default:
            return state;
    }
};
export default remoteReducer;
