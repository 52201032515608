import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Layout from "./components/common/Layout";
import Home from "./components/Home";
import Activity from "./components/Activity";
import Account from "./components/Account";
import EmailConfirmation from "./components/EmailConfirmation";
import Faq from "./components/Faq";
import HowTo from "./components/HowTo";
import KycApplication from "./components/KycApplication";
import KycDetails from "./components/KycDetails";
import DemoKycApplication from "./components/DemoKycApplication";
import KycStatus from "./components/KycStatus";
import Kyc from "./components/Kyc";
import Login from "./components/Login";
import Policy from "./components/Policy";
import Recovery from "./components/Recovery";
import Referral from "./components/Referral";
import SecuritySetting from "./components/SecuritySetting";
import SignupSuccess from "./components/SignupSuccess";
import Signup from "./components/Signup";
import Token from "./components/Token";
import TokenSell from "./components/TokenSell";
import OTCSellSettlement from "./components/OTCSellSettlement";
import Transactions from "./components/Transactions";
import Executions from "./components/Executions";
import Bid from "./components/Bid";
import OtcBuyToken from "./components/OtcBuyToken";
import OtcOrderList from "./components/OtcOrderList";
import Payment from "./components/Payment";
import OTP from "./components/OTP";
import DepositDetails from "./components/DepositDetails";
import WithdrawRequest from "./components/WithdrawRequest";
import CashTransactions from "./components/CashTransactions";
import BankAccount from "./components/BankAccount";
import CreditCardPayment from "./components/CreditCardPayment";
import ReferralCode from "./components/ReferralCode";
import NotificationSetting from "./components/NotificationSetting";
import WalletAddress from "./components/WalletAddress";
import DemoDeposit from "./components/DemoDeposit";
import PromoterApplication from "./components/PromoterApplication";
import PromoterCoupon from "./components/PromoterCoupon";
import PromoterNotification from "./components/PromoterNotification";

import { connect } from 'react-redux';
import { I18nPropvider } from './i18n';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { actionChangeLanguage } from "./store/action/layoutAction";
import { firebaseObserver, userLogout } from "./components/common/Firebase";
import PrivateRoutes from './routes/PrivateRoutes';

const App = (props) => {
    const [authenticated, setAuthenticated] = useState(true);

    useEffect(() => {
        firebaseObserver.subscribe('authStateChanged', data => {
            //console.log("User state changed: " + data);
            setAuthenticated(data);
        });
        return () => { firebaseObserver.unsubscribe('authStateChanged'); }
    }, []);

    useEffect(() => {
        const locale = localStorage.getItem("locale");
        locale && props.actionChangeLanguage(localStorage.locale);
        const retVal = setTimeout(() => userLogout(2), 300000);
        localStorage.setItem("logouttimerid", retVal);
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <I18nPropvider locale={props.locale}>
                <Layout>
                    <Routes>
                        <Route element={<PrivateRoutes auth={authenticated} />}>
                            <Route path="/" element={<Home />} />
                            <Route exact path="/OTP" element={<OTP />} />
                            <Route exact path="/activity" element={<Activity />} />
                            <Route exact path="/account" element={<Account />} />
                            <Route exact path="/email-confirmation" element={<EmailConfirmation />} />
                            <Route exact path="/faq" element={<Faq />} />
                            <Route exact path="/how-to" element={<HowTo />} />
                            <Route exact path="/kyc-application" element={<KycApplication />} />
                            <Route exact path="/kyc-details" element={<KycDetails />} />
                            <Route exact path="/demo-kyc-application" element={<DemoKycApplication />} />
                            <Route exact path="/kyc-status" element={<KycStatus />} />
                            <Route exact path="/kyc" element={<Kyc />} />
                            <Route exact path="/policy" element={<Policy />} />
                            <Route exact path="/referral" element={<Referral />} />
                            <Route exact path="/security" element={<SecuritySetting />} />
                            <Route exact path="/signup-success" element={<SignupSuccess />} />
                            <Route exact path="/tokens" element={<Token />} />
                            <Route exact path="/tokens-sell" element={<TokenSell />} />
                            <Route exact path="/tokens-sell-settlement" element={<OTCSellSettlement />} />                            
                            <Route exact path="/otcbuy" element={<OtcBuyToken />} />
                            <Route exact path="/otcorderlist" element={<OtcOrderList />} />
                            <Route exact path="/payments" element={<Payment />} />
                            <Route exact path="/transactions" element={<Transactions />} />
                            <Route exact path="/executions" element={<Executions />} />                            
                            <Route exact path="/bids" element={<Bid />} />
                            <Route exact path="/deposit" element={<DepositDetails />} />
                            <Route exact path="/withdraw" element={<WithdrawRequest />} />
                            <Route exact path="/cashtransaction" element={<CashTransactions />} />
                            <Route exact path="/bankaccount" element={<BankAccount />} />
                            <Route exact path="/credit-card-demo" element={<CreditCardPayment />} />
                            <Route exact path="/referralcode" element={<ReferralCode />} />
                            <Route exact path="/notificationsetting" element={<NotificationSetting />} />
                            <Route exact path="/walletaddress" element={<WalletAddress />} />
                            <Route exact path="/demodeposit" element={<DemoDeposit />} />
                            <Route exact path="/promoterapply" element={<PromoterApplication />} />
                            <Route exact path="/promotercoupon" element={<PromoterCoupon />} />
                            <Route exact path="/promoternotification" element={<PromoterNotification />} />
                        </Route>
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/signup" element={<Signup />} />
                        <Route exact path="/recovery" element={<Recovery />} />
                    </Routes>
                </Layout>
            </I18nPropvider>
        </LocalizationProvider >
    )
}

const mapStateToProps = state => {
    return {
        locale: state.siteLayout.locale,
    };
};

export default connect(mapStateToProps, { actionChangeLanguage })(App);