import React, { useEffect, useState } from 'react';
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetTranche, actionGetLastTransactionPrice, actionGetOtcTransaction } from "../store/action/remoteAction";
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import { useNavigate } from "react-router-dom";
import BidsTable from "./BidsTable";

const Home = (props) => {
    const [kycProcessInit, setKycProcessInit] = useState(true);
    const [euroBalance, setEuroBalance] = useState(0);
    const [aosBalance, setAosBalance] = useState(0);
    const [aosCurrentPrice, setAosCurrentPrice] = useState(0);
    const [aosPriceDifference, setAosPriceDifference] = useState('');
    const [prevTransactionTime, setPrevTransactionTime] = useState('');
    const [bidTransactionList, setBidTransactionList] = useState([]);
    const [language, setLanguage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        //actionGetUserProfile request send from Navbar
    }, []);

    useEffect(() => {
        if (props?.users?.length > 0) {
            props.actionGetTranche();
            props.actionGetLastTransactionPrice();
            props.actionGetOtcTransaction();

            props.users[0]?.EuroBalance ? setEuroBalance((props.users[0]?.EuroBalance).toFixed(2)) : setEuroBalance(0);
            props.users[0]?.AosBalance ? setAosBalance((props.users[0]?.AosBalance).toFixed(2)) : setAosBalance(0);
            localStorage.setItem('aosBalance', aosBalance);
            if (!(props?.users[0]?.KycStatus === null || props?.users[0]?.KycStatus === undefined || props?.users[0]?.KycStatus.length === 0)) {
                (props?.users[0]?.KycStatus === "Success") ? setKycProcessInit(true) : setKycProcessInit(false);
            } else {
                setKycProcessInit(false);
            }

            if (props?.users[0].UserType === 0 && props?.users[0]?.PromoterNotificationStatus === 1 && localStorage.getItem("promoterNotification") !== "1") {
                window.location.href = '/promoternotification';
            }
        }
        setLanguage(localStorage.getItem("locale"));
    }, [props.users]);

    useEffect(() => {
        if (props?.transactionPrice?.length > 0 && props.transactionPrice[0].LastPrice > 0) {
            setAosCurrentPrice(props.transactionPrice[0].LastPrice);
            let priceDiff = parseFloat(props.transactionPrice[0].LastPrice) - parseFloat(props.transactionPrice[0].PrevLastPrice);
            if (priceDiff === 0) {
                setAosPriceDifference('0%');
            } else {
                let diffPercent = parseFloat(priceDiff / 100).toFixed(2) + '%';
                if (priceDiff > 0) {
                    diffPercent = '+' + diffPercent;
                }
                setAosPriceDifference(diffPercent);
            }
            let sPrevTransactionTime = '';
            if (props.transactionPrice[0].PrevDayLastPriceExist === 1) {   //HH:MM Format
                sPrevTransactionTime = props.transactionPrice[0].PrevLastExeTime.toString(36).substring(11, 16);
            } else {    //YYYY-MM-DD HH:MM Format
                sPrevTransactionTime = props.transactionPrice[0].PrevLastExeTime.toString(36).substring(0, 16).replace("T", " ");
            }
            sPrevTransactionTime = sPrevTransactionTime + '(MLT)';
            setPrevTransactionTime(sPrevTransactionTime);
        }
    }, [props.transactionPrice]);

    useEffect(() => {
        if (props?.bidsList) {
            setBidTransactionList(props.bidsList);
        }
    }, [props.bidsList]);

    useEffect(() => {
        if (props && props.usersError && props?.usersError.toString().includes("403")) {
            navigate("/login");
            localStorage.removeItem("@token");
            localStorage.removeItem("uid");
        }
    }, [props.usersError]);

    return (props?.users?.length > 0) ? (
        <div className="user-panel">
            {/* Last Price */}
            {aosCurrentPrice > 0 &&
                <div className="row">
                    <div className="col-md-6">
                        <div className="tile-item-lastprice tile-primary">
                            <div className="tile-bubbles" />
                            <h6 className="tile-title">{translate('Current-Transaction-Price')}</h6>
                            <h1 className="tile-info">{aosCurrentPrice} {translate('Euro-Aos')}</h1>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="tile-item-lastprice-diff tile-primary">
                            <div className="tile-bubbles" />
                            <h6 className="tile-title">{translate('The-Day-Before')}</h6>
                            <h1 className="tile-info-pricediff"> {aosPriceDifference}</h1>
                            <h6 className="tile-title">{translate('Last-Value-At')} {prevTransactionTime}</h6>
                        </div>
                    </div>
                </div>
            }

            {/* Bids List */}
            {bidTransactionList?.length > 0 &&
                <div>
                    <div className="bids-list-box">
                        <span className="bids-title">{translate('OtcOrder-List')}</span>
                        <div className={bidTransactionList.length === 1 ? "bids-area-1" : bidTransactionList.length === 2 ? "bids-area-2" : "bids-area-n"}>
                            <BidsTable tableData={bidTransactionList} language={language} kycStatus={kycProcessInit} />
                        </div>
                    </div>
                    <div className="gaps-2x" />
                </div>
            }

            {/* User Balance */}
            <div className="row">
                <div className="col-md-6">
                    <div className="tile-item tile-primary">
                        <div className="tile-bubbles" />
                        <h6 className="tile-title">{translate('AOS-Token-Balance')}</h6>
                        <h1 className="tile-info">{aosBalance} AOS</h1>
                    </div>
                </div>
                {/* .col */}
                <div className="col-md-6">
                    <div className="tile-item tile-primary">
                        <div className="tile-bubbles" />
                        <h6 className="tile-title">{translate('Cash-Balance')}</h6>
                        <h1 className="tile-info">{euroBalance} {translate('Euro')}</h1>
                    </div>
                </div>
                {/* .col */}
            </div>

            {/* .info-card */}
            <div className="token-card">
                <div className="token-info">
                    <span className="token-smartag">AOS</span>
                    <h2 className="token-bonus">
                        {" "}
                        {props?.tranche?.output[0]?.TransferValue} <span> {translate('max-aos')} </span>
                    </h2>
                    <ul className="token-timeline">
                        <li>
                            <span>{translate('START-DATE')}</span>{props?.tranche?.output[0]?.StartDate}
                        </li>
                        <li>
                            <span>{translate('END-DATE')}</span>{props?.tranche?.output[0]?.EndDate}
                        </li>
                    </ul>
                </div>
                <div className="token-countdown">
                    <span className="token-countdown-title">{translate('THE-AOS-ICO-ENDS-IN')}</span>
                    <div className="token-countdown-clock" data-date="2022/09/05" />
                </div>
            </div>

            {/* .token-card */}
            <div className="progress-card">
                <h4>{translate('Token-Sale-Progress')}</h4>
                <ul className="progress-info">
                    <li>
                        <span>Raised -</span> 0 AOS
                    </li>
                    <li>
                        <span>TOTAL -</span> 1,000,000 AOS
                    </li>
                </ul>
                <div className="progress-bar">
                    <div className="progress-hcap" style={{ width: "90%" }}>
                        <div>
                            Cap <span>770,000</span>
                        </div>
                    </div>
                    <div className="progress-psale" style={{ width: "12%" }}>
                        <div>
                            Pre Sale <span>{props?.tranche?.output[0]?.TransferValue}</span>
                        </div>
                    </div>
                    <div className="progress-percent" style={{ width: "0%" }} />
                </div>
            </div>
            <div className="gaps-3x" />
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>PreSale</th>
                            <th>Sale Stage 1</th>
                            <th>Sale Stage 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span>Start Date</span>{props?.tranche?.output[0]?.StartDate}
                            </td>
                            <td>
                                <span>Start Date</span>15 August 2023
                            </td>
                            <td>
                                <span>Start Date</span>28 October 2023
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>End Date</span>{props?.tranche?.output[0]?.EndDate}
                            </td>
                            <td>
                                <span>End Date</span>02 September 2023
                            </td>
                            <td>
                                <span>End Date</span>16 November 2023
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Pre-Sale</span>{props?.tranche?.output[0]?.TransferValue}
                            </td>
                            <td>
                                <span>Cap</span>770,000
                            </td>
                            <td>                               
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    ) : "";
}

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        users: state.remote.users,
        usersError: state.remote.usersError,
        tranche: state.remote.tranche,
        transactionPrice: state.remote.transactionPrice,
        bidsList: state.remote.otctransactions
    };
};
export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetTranche,
    actionGetLastTransactionPrice,
    actionGetOtcTransaction
})(Home);
