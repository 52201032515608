import React, { Fragment, useEffect, useState } from 'react';
import connect from "react-redux/es/components/connect";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import translate from "../i18n/translate";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    auth,
    registerWithEmailAndPassword,
} from "./common/Firebase";
//import QRCode from 'qrcode';


const Signup = (props) => {
    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        //console.log(query);
        var vars = query.split("&");
        //console.log(vars); 
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            //console.log(pair);
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return ("");
    };
    const [email, setEmail] = useState("");
    const [coupon, setCoupon] = useState(getQueryVariable("coupon"));
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [name, setName] = useState("");
    //const [qr, setQR] = useState("");
    //const [url, setURL] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [checked, setChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const register = () => {
        const regexPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        const regexEmail = /^[a-z0-9_.@]+$/i
        if (email === '' || !regexEmail.test(email)) {
            alert("Please enter valid email.");
            return;
        }
        if (password === '' || !regexPassword.test(password)) {
            alert("Please enter valid password.");
            return;
        }
        if (!name) {
            alert("Please enter name");
            return;
        }
        if (!checked) {
            alert("Please check terms and conditions.");
            return;
        }
        if (password !== repeatPassword) {
            alert("Password and Repeat password does not match");
            return;
        }

        setIsButtonClicked(true);
        registerWithEmailAndPassword(name, email, password, coupon).then(async res => {
            if (res === "Success") {
                setIsSuccess(true);
            } else {
                setIsButtonClicked(false);
            }
            /*if (url && url.length) {
                setURL(url);
                let qr = await QRCode.toDataURL(url);
                setQR(qr);
            }*/
        });
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (loading) return;
        if (error) alert(error);
        /*if (user && qr) {
            console.log(qr);
            navigate("/");
        }*/
        props.actionToggleNavBar(false);
        props.actionToggleSideBar(false);
        props.actionToggleFooter(false);
    }, [user, loading, isSuccess]);

    return (
        <div className="user-ath-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 text-center">
                        <div className="user-ath-logo">
                            <a href="/">
                                <img
                                    src={require('../assets/images/logo.PNG')}
                                    srcSet={require('../assets/images/logo.PNG')}
                                    alt="icon"
                                />
                            </a>
                        </div>
                        {isSuccess
                            ?
                            <div className="form-note">
                                Thank you for your application.
                                <div className="form-note">
                                    {" "}
                                    We have sent the URL for account registration to the email address you input. Please click on the registration URL to validate your email address.
                                </div>
                                <br />
                                お申込みいただきありがとうございます。
                                <div className="form-note">
                                    {" "}
                                    ご入力いただきましたメールアドレスにアカウント登録用URLを送信いたしました。登録用URLをクリックしメールアドレスの認証をしてください。
                                </div>
                                <br />
                                <a href="/login"><Button variant="contained" size='large'>Login(ログイン)</Button></a>
                            </div>
                            :
                            <div className="user-ath-box">
                                <h4>Create New Account(新しいアカウント)</h4>
                                <form action="signup-success" className="user-ath-form">
                                    <div className="input-item">
                                        <TextField id="name" label="Your Name(名前)" className='input-bordered' value={name}
                                            onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="email" label="Your Email(Eメール)" className='input-bordered' value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="password" type={'password'} label="Password(パスワード)" className='input-bordered' value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="note note-plane note-info">
                                        <em className="fas fa-info-circle" />
                                        <p>
                                            {translate('password-eligibility-message')}
                                        </p>
                                    </div>
                                    <div className="input-item">
                                        <TextField id="repeat-password" type={'password'} label="Repeat Password(パスワード)" className='input-bordered' value={repeatPassword}
                                            onChange={(e) => setRepeatPassword(e.target.value)} />
                                    </div>
                                    <div className="input-item">
                                        <TextField id="coupon" label="Coupon(クーポン)" className='input-bordered' value={coupon}
                                            onChange={(e) => setCoupon(e.target.value)} />
                                    </div>
                                    <div className="input-item text-start">
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox className='input-checkbox' onChange={handleChange} />} label={<Fragment>I agree to <a href="/">Terms</a> and <a href="/"> Policy.(規約とポリシーに同意します)</a></Fragment>} />
                                        </FormGroup>
                                    </div>
                                    <div className="gaps" />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button variant="contained" size='large' disabled={isButtonClicked ? true : false} onClick={register}>Sign Up(サインアップ)</Button>
                                    </div>
                                </form>
                                <div className="or-saparator">
                                    <span>or</span>
                                </div>
                                <div className="form-note">
                                    {" "}
                                    Already a member? <a href="/login">Login(ログイン)</a>
                                </div>
                            </div>}
                        <div className="gaps-2x" />

                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter
})(Signup);
