import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { Stack } from '@mui/material';
import Box from '@mui/system/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import translate from "../i18n/translate";
import connect from "react-redux/es/components/connect";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { actionGetUserTransaction } from "../store/action/remoteAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import { client } from "../store/action/remoteAction";
import { getMultiLanguageLabel, getMultiLanguageMessage } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const Transactions = (props) => {
    const [message, setMessage] = useState("");
    const [language, setLanguage] = useState("");
    const [isError, setIsError] = useState(false);
    let ROOT_WALLET = "Gatsby Malta";
    const navigate = useNavigate();

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        props.actionGetUserTransaction('');
    }, []);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const Message = ({ message }) => (
        <section>
            <p style={{ color: isError ? "red" : "blue" }}>{message}</p>
        </section>
    );

    const [priceInput, setPrice] = useState(0);
    const [qtyInput, setQty] = useState(0);

    const [state, setState] = React.useState({
        completed: false,
        pending: false,
        cancelled: false,
        buy: false,
        sell: false,
        Price: false,
        Qty: false
    });

    const submitChange = () => {
        resetTimout();
        props.actionGetUserTransaction(formFilter());
    };

    const CancelOrder = async (e) => {
        resetTimout();
        if (window.confirm(getMultiLanguageMessage('msg_OrderCancel_Confirm', language))) {
            let isQueryTransaction = false;
            await client(localStorage.getItem("@token")).put(`${BASE_URL}/transactions/status/` + props?.transactions[e].TransactionID, { "status": "CANCELLED" }).then((response) => {
                if (response.status == 200) {
                    setMessage(getMultiLanguageMessage('msg_OrderCancel_Success', language));
                    isQueryTransaction = true;
                    setIsError(false);
                } else {
                    setMessage(getMultiLanguageMessage('msg_OrderCancel_Failed', language));
                    setIsError(true);
                }
            }).catch(error => {
                setMessage(getMultiLanguageMessage('msg_OrderCancel_Failed', language));
                console.log(error);
                setIsError(true);
            });
            if (isQueryTransaction) {
                props.actionGetUserTransaction(formFilter());
            }
        }
    };

    const handleChange = (event) => {
        resetTimout();
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const formFilter = () => {
        let filterText = "";
        if (completed) {
            filterText += ',"TxnStatus":"SUCCESS"';
        }
        if (pending) {
            filterText += ',"TxnStatus":"CREATED"';
        }
        if (cancelled) {
            filterText += ',"TxnStatus":"DECLINED"';
        }
        if (buy) {
            filterText += ',"Buy":' + 1;
        }
        if (sell) {
            filterText += ',"Sell":' + 1;
        }
        if (Price) {
            filterText += ',"Quantity":' + priceInput;
        }
        if (Qty) {
            filterText += ',"Amount":' + qtyInput;
        }
        return filterText;
    };

    const { completed, pending, cancelled, buy, sell, Price, Qty } = state;
    const error = [completed, pending, cancelled, buy, sell, Price, Qty].filter((v) => v).length !== 2;

    /*useEffect(() => {
        console.log('Hello TXN '+JSON.stringify(props.transactions));
    },[props.transactions]);*/
    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{translate('Transactions')}</h2>
            {/* if there is no transaction you can use this code */}
            {message ? (
                <Message message={message} />
            ) : ''}

            <Box sx={{ display: 'flex' }}>
                <Accordion sx={{ display: 'nowrap', marginBottom: '10' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>{translate('Filters')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Filter By</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={completed} onChange={handleChange} name="completed" />
                                    }
                                    label={translate('Txn-Completed')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={cancelled} onChange={handleChange} name="cancelled" />
                                    }
                                    label={translate('Txn-Cancelled')}
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormLabel component="legend">Add</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={pending} onChange={handleChange} name="pending" />
                                    }
                                    label={translate('Txn-Pending')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={buy} onChange={handleChange} name="buy" />
                                    }
                                    label={translate('Buy-Order')}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={sell} onChange={handleChange} name="sell" />
                                    }
                                    label={translate('Sell-Order')}
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormLabel component="legend">Add</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={Price} onChange={handleChange} name="Price" />
                                    }
                                    label={translate('buy-price')}
                                />
                            </FormGroup>
                            <FormHelperText>Criteria</FormHelperText>
                            <TextField id="buy-price" label="Enter" variant="outlined" size="small" onChange={(e) => setPrice(e.target.value)} />
                        </FormControl>
                        <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
                            <FormLabel component="legend">Add</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={Qty} onChange={handleChange} name="Qty" />
                                    }
                                    label={translate('buy-quantity')}
                                />
                            </FormGroup>
                            <FormHelperText>Criteria</FormHelperText>
                            <TextField id="buy-qty" label="Enter" variant="outlined" size="small" onChange={(e) => setQty(e.target.value)} />
                        </FormControl>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <Button variant="contained" onClick={() => submitChange()}>{translate('Search')}</Button>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
            </Box>
            {/*
            <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />} sx={{ display: 'flex', marginTop: 2 }}>
                <div><Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: '#f7cdffc2',
                        border: '1px dashed grey'
                    }} />OTC Sell</div>
                <div><Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: 'cornflowerblue',
                        border: '1px dashed grey'
                    }} />Sell</div>
                <div><Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: 'antiquewhite',
                        border: '1px dashed grey'
                    }} />Commission</div>
                <div><Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: '#b1ffe5',
                        border: '1px dashed grey'
                    }} />Gift</div>
                <div><Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: '#fff',
                        border: '1px dashed grey'
                    }} />Others</div>
            </Stack>
            */}
            <div className="gaps-2x" />
            {/* NEW TABLE */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table" className="data-table tranx-table">
                    <TableHead>
                        <TableRow>
                            <TableCell width='14%' align="center" component="th" className="tranx-status">{translate('TransactionStatus')}</TableCell>
                            <TableCell width='13%' align="center" component="th" className="tranx-date">{translate('DateTime')}</TableCell>
                            <TableCell width='8%' align="center" component="th" className="tranx-buysell">{translate('Buy-Sell')}</TableCell>
                            <TableCell width='9%' align="center" component="th" className="tranx-token">{translate('Tokens')}</TableCell>
                            <TableCell width='11%' align="center" component="th" className="tranx-amount">{translate('UnitPrice')}</TableCell>
                            <TableCell width='12%' align="center" component="th" className="tranx-amount">{translate('Amount')}</TableCell>
                            <TableCell width='11%' align="center" component="th" className="tranx-amount">{translate('Commission')}</TableCell>
                            <TableCell width='15%' align="center" component="th" className="tranx-no">{translate('TransactionNo')}</TableCell>
                            <TableCell width='7%' align="center" component="th" className="Cancel"></TableCell>
                            {/*<TableCell align="center" component="th" className="tranx-action" />*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.transactions?.map((txn, index) => {
                            return <TableRow key={txn.TransactionID}>
                                <TableCell width='14%' align='center' color={(txn.BuySellFlag === 2 || txn.BuySellFlag === 3) ? 'red' : 'blue'}>
                                    <span>
                                        {
                                            (txn.ProcessStatus === "PROCESSED") ? translate('Txn-Completed') :
                                                (txn.TxnStatus === "CANCELLED") ? translate('Txn-Cancelled') :
                                                    (txn.TxnStatus === "DECLINED") ? translate('Txn-Declined') :
                                                        (txn.ProcessStatus === "IN-PROGRESS") ? translate('Txn-Progress') :
                                                            (txn.TxnStatus === "EXPIRED") ? translate('Txn-Expired') :
                                                                (txn.TxnStatus === "ERROR") ? translate('Txn-Error') : translate('Txn-Pending')
                                        }
                                    </span>
                                </TableCell>
                                <TableCell width='13%' align='center'>
                                    <span>{txn.DateCreated}</span>
                                </TableCell>
                                <TableCell width='8%' align='center'>
                                    <div className={(txn.BuySellFlag === 2 || txn.BuySellFlag === 3 || txn.BuySellFlag === 8) ? 'Message-Red' : 'Message-Blue'}>{txn.BuySellFlag === 5 ? translate('Transaction-Bonus') : (txn.BuySellFlag === 4 || txn.BuySellFlag === 3) ? translate('Transaction-Gift') : (txn.BuySellFlag === 2 || txn.BuySellFlag === 8) ? translate('Transaction-Sell') : translate('Transaction-Buy')}</div>
                                </TableCell>
                                <TableCell width='9%' align='center'>
                                    {((txn.BuySellFlag === 2 || txn.BuySellFlag === 7) && txn.OtcFlag === 1 && (txn.UsedQuantity > 0 || txn.PendingQuantity > 0) && (txn.UsedQuantity < txn.Quantity)) ?
                                        <div>
                                            <span>{txn.BuySellFlag === 2 ? '-' : ''}{txn.UsedQuantity + txn.PendingQuantity}</span>
                                            <span>({txn.UsedQuantity + txn.PendingQuantity}/{txn.Quantity})</span>
                                        </div>
                                        :
                                        <span>{(txn.BuySellFlag === 2 || txn.BuySellFlag === 3 || txn.BuySellFlag === 8) ? '-' : '+'}{txn.Quantity}</span>
                                    }
                                    {txn.CryptoAsset}
                                </TableCell>
                                <TableCell width='11%' align='center'>
                                    <span>{txn.UnitPrice}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='12%' align='center'>
                                    <span>{txn.Amount}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='11%' align='center'>
                                    <span>{txn.CommissionAmount}</span>{translate('Euro')}
                                </TableCell>
                                <TableCell width='15%' align='center'>
                                    <span>{txn.TransactionID.length > 12 ? txn.TransactionID.substring(txn.TransactionID.length - 12) : txn.TransactionID}</span>
                                </TableCell>
                                <TableCell width='7%' align='center'>
                                    {((txn.OtcFlag === 0 && (txn.BuySellFlag === 1 || txn.BuySellFlag === 2 || txn.BuySellFlag === 3) && (txn.TxnStatus === "CREATED" || txn.TxnStatus === "SUCCESS")) ||
                                        (txn.OtcFlag === 1 && txn.BuySellFlag === 2 && txn.TxnStatus === "CREATED") ||
                                        (txn.OtcFlag === 1 && (txn.BuySellFlag === 1 || txn.BuySellFlag === 7 || txn.BuySellFlag === 8) && txn.TxnStatus === "SUCCESS")) &&
                                        txn.ProcessStatus === "CREATED" ? <IconButton color="primary" aria-label="add to shopping cart" onClick={() => CancelOrder(index)}>
                                        <CancelIcon data-index={index} />
                                    </IconButton> : ""}
                                </TableCell>
                                {/*<TableCell className="tranx-action" align='center'>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#tranxApproved">
                                <em className="ti ti-more-alt" />
                            </a>
                    </TableCell>*/}
                            </TableRow>
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* NEW TABLE ENDS */}
        </div>

    );
};
const mapStateToProps = state => {
    return {
        transactions: state.remote.transactions
    };
};
export default connect(mapStateToProps, {
    actionGetUserTransaction
})(Transactions);
