import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import translate from "../i18n/translate";
import { actionToggleFooter, actionToggleNavBar, actionToggleSideBar } from "../store/action/layoutAction";
import { actionGetCurrencyEuro, actionGetAOSBalance, actionGetCommissionRate } from "../store/action/remoteAction";
import connect from "react-redux/es/components/connect";
import { client } from "../store/action/remoteAction";
import ConfirmDialog from "./ConfirmDialog";
import { getMultiLanguageMessage, getMultiLanguageLabel } from '../i18n/multiLanguage';
import { resetTimout } from "./common/Firebase";

let BASE_URL = process.env.REACT_APP_SERVER_URL_API;

const TokenSell = (props) => {
    const [aosBalance, setAosBalance] = useState(0);
    const [isGift, setGift] = useState(false);
    const [isPublicPurchasePrice, setIsPublicPurchasePrice] = useState(false);
    const [isPartialOrderFlag, setIsPartialOrderFlag] = useState(false);
    const [address, setAddress] = useState('');
    const [aos, setAos] = useState(0);
    const [euro, setEuro] = useState(0);
    const [aosRate, setAosRate] = useState(0);
    const [aosSellPrice, setAosSellPrice] = useState(0);
    const [commissionID, setCommissionID] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [language, setLanguage] = useState("");
    const [pageId, setPageId] = useState(0);
    const [isValidatePrice, setIsValidatePrice] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const [Dialog, showConfirmDialog] = ConfirmDialog(
        getMultiLanguageLabel('lbl_ConfirmDialog_Title', language),
        getMultiLanguageMessage('msg_Confirm_Replace_PPP', language).replace('xxx', aosRate),
        getMultiLanguageLabel('lbl_Change', language),
        getMultiLanguageLabel('lbl_DoNotChange', language)
    );

    const goToConfirmationPage = async () => {
        resetTimout();

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (isGift && !address) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }
        if (!isGift && parseFloat(aosSellPrice) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (!isGift && parseFloat(euro) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }

        let nPageId = 0;
        if (isValidatePrice) {
            const isOk = await showConfirmDialog();
            if (isOk) {
                publicPurchasePriceChange();
            } else {
                nPageId = 1;
            }
            setIsValidatePrice(false);
        } else {
            nPageId = 1;
        }

        if (isGift && nPageId === 1) {
            let uid = localStorage.getItem("uid");
            const request = {
                "UserID": uid,
                "ToWalletID": address
            }
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/wallets/gift`, request).then((response) => {
                if (response.data && response.data.length === 1) {
                    setPageId(1);
                } else {
                    alert(getMultiLanguageMessage('msg_WalletNotFound_Error', language));
                }
            }).catch(error => {
                alert("Error! Please contact customer support");
                console.log(error);
            });
        } else {
            setPageId(nPageId);
        }
    };

    const goToPrevPage = () => {
        resetTimout();
        setPageId(0);
    };

    const submitSellTransaction = async () => {
        resetTimout();
        let uid = localStorage.getItem("uid");

        if (!aos || !aos.match(/^\d{1,}(\.\d{0,2})?$/) || aos <= 0) {
            alert(getMultiLanguageMessage('msg_IncorrectCoin_Error', language));
            return;
        }
        if (parseFloat(aosBalance) < aos) {
            alert(getMultiLanguageMessage('msg_InsufficientCoin_Error', language));
            return;
        }
        if (isGift && !address) {
            alert(getMultiLanguageMessage('msg_EmptyWalletAddress', language));
            return;
        }
        if (!isGift && parseFloat(aosSellPrice) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }
        if (!isGift && parseFloat(euro) <= 0) {
            alert(getMultiLanguageMessage('msg_InvalidAmount_Error', language));
            return;
        }

        if (window.confirm(getMultiLanguageMessage('msg_Confirm_Proceed', language))) {
            setIsButtonClicked(true);
            const request = {
                "UserId": uid,
                "CryptoAsset": "AOS",
                "BuySellFlag": isGift ? 3 : 2,
                "Currency": "EURO",
                "FromWalletId": uid,
                "ToWalletID": isGift ? address : "",
                "Quantity": "" + aos + "",
                "UnitPrice": isGift ? 0 : aosSellPrice,
                "Amount": isGift ? 0 : euro,
                "CommissionID": isGift ? 0 : commissionID,
                "CommissionAmount": isGift ? 0 : commissionAmount,
                "PaymentType": 0,
                "OtcFlag": 1,
                "PartialOrderFlag": isPartialOrderFlag ? 1 : 0
            };
            await client(localStorage.getItem("@token")).post(`${BASE_URL}/transactions/`, request).then((response) => {
                setAos(0);
                setEuro(0);
                window.location.href = '/transactions';
            }).catch(error => {
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error! Please contact customer support");
                }
                console.log(error);
                setIsButtonClicked(false);
            });
        }
    };

    const giftChange = () => {
        resetTimout();
        setGift(!isGift);
    };

    const publicPurchasePriceChange = () => {
        resetTimout();
        let nAmount = 0;
        if (!isPublicPurchasePrice) {
            setAosSellPrice(aosRate);
            if (parseFloat(aos) > 0 && parseFloat(aosRate)) {
                nAmount = (parseFloat(aos) * parseFloat(aosRate)).toFixed(2);
            }
            setEuro(nAmount);
            calculateCommissionAmount(aos, nAmount);
            setIsValidatePrice(false);
        }
        setIsPublicPurchasePrice(!isPublicPurchasePrice);
    };

    const partialOrderOnChange = (e) => {
        resetTimout();
        setIsPartialOrderFlag(!isPartialOrderFlag);
    };

    const handleChange = (e) => {
        resetTimout();
        let nAmount = 0;
        switch (e.target.id) {
            case "outlined-start-adornment-aos":
                let requestedAos = e.target.value.replace(/[^0-9.]/g, "");
                setAos(requestedAos);
                if (parseFloat(requestedAos) > 0 && parseFloat(aosSellPrice) > 0) {
                    nAmount = (parseFloat(requestedAos) * parseFloat(aosSellPrice)).toFixed(2);
                }
                setEuro(nAmount);
                calculateCommissionAmount(requestedAos, nAmount);
                break;
            case "outlined-start-adornment-rate":
                let requestedPrice = e.target.value.replace(/[^0-9.]/g, "");
                setAosSellPrice(requestedPrice);
                if (parseFloat(aos) > 0 && parseFloat(requestedPrice) > 0) {
                    nAmount = (parseFloat(aos) * parseFloat(requestedPrice)).toFixed(2);
                }
                setEuro(nAmount);
                calculateCommissionAmount(aos, nAmount);
                if (parseFloat(requestedPrice) > 0 && parseFloat(requestedPrice) < parseFloat(aosRate)) {
                    setIsValidatePrice(true);
                } else {
                    setIsValidatePrice(false);
                }
                break;
            default: console.log('err');
        }
    };

    const addressChange = (e) => {
        resetTimout();
        setAddress(e.target.value);
    };

    const calculateCommissionAmount = (sellQuantity, sellAmount) => {
        if (!props.commissionRates || props.commissionRates.length <= 0) {
            alert("Unexpected Error. Please contact customer support.");
            return;
        }
        let commissionRate = props.commissionRates[0];
        setCommissionID(commissionRate.CommissionID);
        let nCommissionAmount = 0;
        if (commissionRate.CommissionCalcType === 1) {
            nCommissionAmount = parseFloat((parseFloat(sellQuantity) * parseFloat(commissionRate.CommissionValue)).toFixed(2));
        } else if (commissionRate.CommissionCalcType === 2) {
            nCommissionAmount = parseFloat((parseFloat(sellAmount) * (parseFloat(commissionRate.CommissionValue) / 100)).toFixed(2));
        }
        if (!nCommissionAmount) {
            nCommissionAmount = 0;
        }
        setCommissionAmount(nCommissionAmount);
        let nTotalAmount = 0;
        if (parseFloat(sellAmount) > 0) {
            nTotalAmount = (parseFloat(sellAmount) - parseFloat(nCommissionAmount)).toFixed(2);
        }
        setTotalAmount(nTotalAmount);
    };

    useEffect(() => {
        props.actionToggleNavBar(true);
        props.actionToggleSideBar(true);
        props.actionToggleFooter(true);
        props.actionGetCurrencyEuro();
        props.actionGetAOSBalance();
        props.actionGetCommissionRate(2);
    }, []);

    useEffect(() => {
        if (props.euroRate?.length >= 1)
            setAosRate(parseFloat(props?.euroRate[0]?.Rate).toFixed(2));
    }, [props.euroRate]);

    useEffect(() => {
        setLanguage(localStorage.getItem("locale"));
        if (props?.userBalances?.length > 0) {
            props.userBalances[0]?.Balance ? setAosBalance((props.userBalances[0]?.Balance).toFixed(2)) : setAosBalance(0);
        }
    }, [props.userBalances]);

    return (
        <div className="user-panel">
            <h2 className="user-panel-title">{isGift ? translate('Gift-Tokens') : translate('Menu-OTCSell-Open')}</h2>
            <h2 className="input-item-label">{translate('AOS-Token-Balance')} = {aosBalance}</h2>

            {pageId === 0 &&
                <div>
                    <div className="gaps-1x" />
                    <div className="row">
                        <div className="col-md-8" style={{ padding: 0 }}>
                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        size="small"
                                        label={translate('Order-Quantity')}
                                        id="outlined-start-adornment-aos"
                                        sx={{ width: '24.5ch' }}
                                        value={aos}
                                        defaultValue={aos}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">AOS</InputAdornment>,
                                        }}
                                    />
                                </div>

                                {!isGift &&
                                    <div>
                                        <div className="gaps-2x" />
                                        <TextField
                                            size="small"
                                            label={translate('Rate')}
                                            id="outlined-start-adornment-rate"
                                            sx={{ width: '24.5ch' }}
                                            value={aosSellPrice}
                                            defaultValue={aosSellPrice}
                                            onChange={handleChange}
                                            disabled={isPublicPurchasePrice ? true : false}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={publicPurchasePriceChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            checked={isPublicPurchasePrice ? true : false}
                                        />} label={translate('public-purchase-price')} />
                                        &nbsp;
                                        <FormControlLabel control={<Checkbox
                                            onChange={giftChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} label={translate('Gift')} />
                                    </div>
                                }

                                {isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={giftChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            checked={true}
                                        />} label={translate('Gift')} />
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <FormControlLabel control={<Checkbox
                                            onChange={partialOrderOnChange}
                                            checked={isPartialOrderFlag}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} label={translate('Partial-Order')} />
                                    </div>
                                }

                                {isGift &&
                                    <div>
                                        <div class="input-item input-with-label">
                                            <label for="swalllet" class="input-item-label">{translate('Select-Wallet')}</label>
                                            <select class="input-bordered" name="swalllet" id="swalllet">
                                                <option value="eth">AOS</option>
                                            </select>
                                        </div>

                                        <div class="input-item input-with-label">
                                            <label for="token-address" class="input-item-label">{translate('Enter-Address')}:</label>
                                            <input class="input-bordered" type="text" id="token-address" name="token-address" onChange={addressChange} value={address} defaultValue={address} />
                                        </div>
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <div className="gaps-1x" />
                                        <TextField
                                            size="small"
                                            label={translate('Commission-Amount')}
                                            id="commission-amount"
                                            sx={{ width: '24.5ch' }}
                                            value={commissionAmount}
                                            defaultValue={commissionAmount}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                            }}
                                            disabled={true}
                                        />
                                    </div>
                                }

                                {!isGift &&
                                    <div>
                                        <div className="gaps-1x" />
                                        <TextField
                                            size="small"
                                            label={translate('Payment-Amount')}
                                            id="payment-amount"
                                            sx={{ width: '24.5ch' }}
                                            value={totalAmount}
                                            defaultValue={totalAmount}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">EUR</InputAdornment>,
                                            }}
                                            disabled={true}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="gaps-1x" />
                    <button className="btn btn-primary" onClick={goToConfirmationPage}>{translate('To-Confirm-Page')}</button>
                    <div className="gaps-1x" />
                    <Dialog />
                </div>
            }

            {pageId === 1 &&
                <div>
                    <table width='70%'>
                        <tr>
                            <td align='left' >{translate('Order-Quantity')} :</td>
                            <td align='right' >{aos} AOS</td>
                        </tr>
                        {!isGift &&
                            <tr>
                                <td align='left' >{translate('Rate')} :</td>
                                <td align='right'>{aosSellPrice} EUR/AOS </td>
                            </tr>
                        }
                        {!isGift &&
                            <tr>
                                <td align='left'>{translate('Commission-Amount')} :</td>
                                <td align='right'>{commissionAmount} EUR</td>
                            </tr>
                        }
                        {!isGift &&
                            <tr>
                                <td align='left' >{translate('Payment-Amount')} :</td>
                                <td align='right'>{totalAmount} EUR</td>
                            </tr>
                        }
                        {/*!isGift &&
                            <tr>
                                <td align='left'>{translate('Payment-Type')} :</td>
                                <td align='right'>{translate('Payment-By-WalletBalance')} </td>
                            </tr>
                        */}
                        {isGift &&
                            <tr>
                                <td align='left' colspan={2}>{translate('WalletAddress')} :</td>
                            </tr>
                        }
                        {isGift &&
                            <tr>
                                <td align='left' colspan={2} className='td-wrap-text' >{address} </td>
                            </tr>
                        }
                    </table>

                    {isButtonClicked &&
                        <>
                            <div className="gaps-1x" />
                            <img width={80} src={require('../assets/images/loading.gif')} srcSet={require('../assets/images/loading.gif')} alt="loading" />
                        </>
                    }
                    <div className="gaps-1x" />
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={goToPrevPage}>{translate('Back-Btn')}</button>&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={isButtonClicked ? true : false} onClick={submitSellTransaction}>{translate('Submit')}</button>
                    <div className="gaps-1x" />
                </div>
            }
            {/* form */}
        </div >

    );
};

const mapStateToProps = state => {
    return {
        navBar: state.siteLayout.navBar,
        euroRate: state.remote.currencyEuro,
        userBalances: state.remote.userBalances,
        commissionRates: state.remote.commissionRates,
    };
};

export default connect(mapStateToProps, {
    actionToggleNavBar,
    actionToggleSideBar,
    actionToggleFooter,
    actionGetCurrencyEuro,
    actionGetAOSBalance,
    actionGetCommissionRate
})(TokenSell);
